import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Container from '../../atoms/Container/Container';

import PostCard from '../../molecules/PostCard/PostCard';

import styles from './PostList.module.css';

// TODO: Incomplete component - needs to be finished. Please update the readme when complete.

const PostList = ({ posts, viewMode, cardSize, titleOnly }) => {
  const classes = useMemo(() => {
    const initClass = [];
    if (viewMode === 'grid') {
      initClass.push(styles.postsGrid);
    } else {
      initClass.push(styles.postsList);
    }
    if (cardSize) {
      initClass.push(styles[cardSize]);
    }
    return initClass.join(' ');
  }, [viewMode, cardSize]);

  return (
    <Container>
      <div className={classes}>
        {posts.map(post => (
          <PostCard
            size={cardSize}
            key={post.id}
            title={post.title}
            excerpt={post.excerpt}
            slug={post.slug}
            categories={post.categories}
            content={post.content}
            authorName={post.author_name}
            authorUri={post.author_uri}
            date={post.published_date}
            viewMode={viewMode}
            thumbnail={post.thumbnail_path}
            titleOnly={titleOnly}
          />
        ))}
      </div>
    </Container>
  );
};

PostList.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  viewMode: PropTypes.oneOf(['list', 'grid'])
};

PostList.defaultProps = {
  viewMode: 'list'
};

export default PostList;

export const wpPostListFields = graphql`
  fragment PostListFields on WpPost {
    id
    title
    excerpt
    uri
    content
    author {
      node {
        name
        uri
        avatar {
          url
        }
      }
    }
    date(formatString: "MMMM DD, YYYY")
    featuredImage {
      node {
        uri
        sourceUrl
      }
    }
    tags {
      nodes {
        uri
        name
      }
    }
    categories {
      nodes {
        name
        uri
        slug
      }
    }
    comments {
      nodes {
        id
        author {
          node {
            name
            id
            url
          }
        }
        content
        date(formatString: "MMM DD, YYYY")
      }
    }
    databaseId
  }
`;
